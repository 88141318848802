import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

import { Link, graphql } from 'gatsby';

import '../fonts/fonts-post.css';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Bio from '../components/Bio';
import { rhythm } from '../utils/typography';

class TagsPage extends React.Component {
  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const group = get(this.props, 'data.allMarkdownRemark.group');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO />
        <div>
          <h1>Tags</h1>
          <ul>
            {group.map(tag => (
              <li key={tag.fieldValue}>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {'#' + tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <hr />
        <aside>
          <h3
            style={{
              fontFamily: 'Montserrat, sans-serif',
              marginTop: rhythm(0.25),
            }}
          >
            <Link
              style={{
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'var(--pink)',
              }}
              to={'/'}
            >
              pCodeLab
            </Link>
          </h3>
          <Bio />
        </aside>
      </Layout>
    );
  }
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
